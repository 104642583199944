import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { getTestID } from '../../utils/utils';
import GeberitSpinner from '../ui/GeberitSpinner';
import CatalogueTable from './CatalogueTable/CatalogueTable';
import Filters from './Filters';
import { useFilterState } from './hooks';
import { useCatalogueList } from '../../api/hooks';
import Alert from '../ui/Alert';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import FiltersMobile from './FiltersMobile';

function CatalogueList() {
  const { t } = useTranslation();
  const [uuid] = useLocalStorage('uuid');

  const {
    filters, setStandards, setLocales, setStatuses, clearFilters,
  } = useFilterState();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useCatalogueList(uuid, filters);

  const cataloguesShown = data?.pages[0] !== '' && data?.pages?.reduce((count, curr) => count + curr.catalogues.length, 0);
  const cataloguesTotal = data?.pages[0]?.rowTotal;
  const hasCatalogues = isSuccess && data?.pages[0]?.catalogues?.length > 0;
  const noCataloguesForUser = isSuccess && cataloguesTotal === '0';
  const noCataloguesReturnedByFilter = isSuccess && data?.pages[0] === '';

  const cataloguesCount = `${cataloguesShown} ${t('catalogues.pagination.of')} ${cataloguesTotal}`;
  const heading = hasCatalogues ? `${t('catalogues.heading')} (${cataloguesCount})` : t('catalogues.heading');
  const headingSmall = hasCatalogues ? `${t('catalogues.headingSmall')} (${cataloguesCount})` : t('catalogues.heading');

  // Add breakpoint values for different screen sizes using your custom breakpoints
  const tableView = useBreakpointValue({
    xs: 'mobile', // For 320px to 767px, we'll use a mobile view
    sm: 'small',
    md: 'small',
    lg: 'large',
  });

  return (
    <Box width="inherit">
      {tableView === 'mobile' ? (
        <Flex justify="space-between" align="center" width="100%" padding={6}>
          <Heading
            data-testid={getTestID('H1HeaderForCatalogueOverview')}
            as="h1"
            size="h2"
          >
            {headingSmall}
          </Heading>
          <FiltersMobile
            catalogueUuid={uuid}
            filters={filters}
            setStandards={setStandards}
            setLocales={setLocales}
            setStatuses={setStatuses}
          />
        </Flex>
      ) : (
        <>
          <Heading
            data-testid={getTestID('H1HeaderForCatalogueOverview')}
            as="h1"
            size="h1"
          >
            {heading}
          </Heading>
          <Filters
            catalogueUuid={uuid}
            filters={filters}
            setStandards={setStandards}
            setLocales={setLocales}
            setStatuses={setStatuses}
          />
        </>
      )}
      {noCataloguesForUser && (
        <>
          <Text data-testid={getTestID('NoCatalogueAvailableText')} as="span">
            {t('catalogues.none')}
          </Text>
          <Link to="/transformations" as={NavLink}>
            {t('catalogue.create')}
          </Link>
        </>
      )}
      {noCataloguesReturnedByFilter && (
        <>
          <Text as="span">{t('catalogues.noneForFilter')}</Text>
          <Button
            variant="link"
            onClick={() => clearFilters()}
          >
            {t('catalogues.resetFilters')}
          </Button>
        </>
      )}
      {isLoading && <GeberitSpinner />}
      {isError && (
        <Alert variant="error">
          {error?.message
            || JSON.stringify(error)}
        </Alert>
      )}
      {hasCatalogues && (<CatalogueTable data={data} />)}
      <Flex>
        <Spacer />
        {hasNextPage && (
          <Button
            onClick={fetchNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
            leftIcon={<ArrowDownIcon />}
          >
            {isFetchingNextPage ? t('loadingMore') : t('loadMore')}
          </Button>
        )}
        <Spacer />
        {hasCatalogues && (
          <Text data-testid={getTestID('CatalogueCounter')} alignSelf="center">
            {cataloguesCount}
          </Text>
        )}
      </Flex>
    </Box>
  );
}

export default CatalogueList;
