import { useTranslation } from 'react-i18next';
import {
  VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, IconButton,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useCatalogueFilters } from '../../api/hooks';
import Alert from '../ui/Alert';
import MultiSelectDropdown from '../ui/MultiSelectDropdown';
import FilterIcon from '../../icons/FilterIcon';

function FiltersMobile(props) {
  const {
    setStandards,
    setLocales,
    setStatuses,
    filters,
  } = props;
  const {
    standards,
    locales,
    statuses,
  } = filters;
  const { t } = useTranslation();

  const { data, error, isError } = useCatalogueFilters();

  if (isError) {
    return (
      <Alert variant="error">
        {error?.message || JSON.stringify(error)}
      </Alert>
    );
  }

  return (
    <Box marginBottom="20px">
      <VStack justifyContent="flex-end" mb={4} width="100%">
        {/* Accordion containing the filter dropdowns */}
        <Accordion allowToggle width="100%">
          <AccordionItem border="none">
            <AccordionButton maxW="fit-content" as={IconButton} icon={<FilterIcon />} aria-label={t('filters.label')} />
            <AccordionPanel padding="0" mt={4}>
              {data && (
                <VStack spacing={4} align="stretch">
                  <MultiSelectDropdown
                    onChange={setStandards}
                    options={data?.standards}
                    selectedValues={standards}
                    getOptionLabel={(o) => t(`transformations.${o}.pretty_name`)}
                    label={t('exchangeStandard')}
                    closeOnSelect={false}
                  />
                  <MultiSelectDropdown
                    onChange={setLocales}
                    options={data?.locales}
                    selectedValues={locales}
                    getOptionLabel={(o) => t(`locales.${o}`)}
                    label={t('locale.heading')}
                    closeOnSelect={false}
                  />
                  {data?.statuses?.length > 1 && (
                    <MultiSelectDropdown
                      onChange={setStatuses}
                      options={data.statuses}
                      selectedValues={statuses}
                      getOptionLabel={(o) => t(`statuses.options.${o}`)}
                      label={t('statuses.heading')}
                      closeOnSelect={false}
                    />
                  )}
                </VStack>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
    </Box>
  );
}

FiltersMobile.propTypes = {
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  setStandards: PropTypes.func.isRequired,
  setLocales: PropTypes.func.isRequired,
  setStatuses: PropTypes.func.isRequired,
};

export default FiltersMobile;
